import { FC } from "react";

import { theme } from "ovou-ui";

import { useGetWalkthrough } from "../../api/useGetWalkthrough";
import { Text, Image, Flex, Modal, CButton as Button } from "components";
import { generateMediaPath } from "../../utils";
import { useRouter } from "hooks";
import { MAIN_SITE } from "../../constants";
import { UPGRADE_PAGES } from "../../constants/pages";

interface IWalkthroughModalProps {
  walkthroughId: string;
  isWalkthroughOpen: boolean;
  closeWalkthrough: () => void;
  confirmButton?: string;
  defaultLink?: string;
}

const WalkthroughModal: FC<IWalkthroughModalProps> = ({
  walkthroughId,
  isWalkthroughOpen,
  closeWalkthrough,
  confirmButton,
  defaultLink,
}) => {
  const { data: walkthrough = {} } = useGetWalkthrough(walkthroughId);
  const router = useRouter();

  const handleCTAButton = (cta_link?: string) => {
    if (!defaultLink) {
      window.location.href =
        cta_link || `${MAIN_SITE}/${UPGRADE_PAGES.SELECT_YOUR_PLAN}`;
    } else {
      router.push(defaultLink);
    }
  };

  return (
    <>
      <Modal
        label=""
        children={
          <Flex flexDirection="column" width="100%">
            <Text
              fontSize="24px"
              fontWeight="800"
              letterSpacing="-0.01em"
              color="black"
            >
              {walkthrough.title}
            </Text>

            {walkthrough.sections?.map((section, index) => (
              <div key={index}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: section.message,
                  }}
                ></div>
                {section.picture && (
                  <Image
                    src={generateMediaPath(section.picture)}
                    margin="8px auto"
                    boxShadow={`0 1px 3px 3px ${theme.palette.ui.greys.grey3}`}
                    borderRadius="10px"
                  />
                )}
                {section.cta_label && (
                  <Button
                    width="100%"
                    margin="24px 0"
                    onClick={() => handleCTAButton(section?.cta_link)}
                  >
                    {section.cta_label}
                  </Button>
                )}
              </div>
            ))}
            {confirmButton && (
              <Button
                width="100%"
                variant="outline"
                margin="24px 0"
                onClick={closeWalkthrough}
              >
                {confirmButton}
              </Button>
            )}
          </Flex>
        }
        isOpen={isWalkthroughOpen}
        onClose={closeWalkthrough}
      />
    </>
  );
};

export default WalkthroughModal;
