import {
  AuthLayout,
  // MainLayout,
  NewLayout,
  OnboardingLayout,
} from "../layouts";
import {
  AnalyticsPage,
  BusinessPage,
  ContactDetailPage,
  ContactsPage,
  DevicesPage,
  DirectoryPage,
  // EditAboutPage,
  // EditBasicInfoPage,
  // EditContactInformationPage,
  // EditLinksPage,
  // EditProfileLinkPage,
  // EditSocialNetworks,
  // EditVideoPage,
  ForgotPasswordPage,
  IntegrationsPage,
  LoginPage,
  LoginWithTokenPage,
  LogoutPage,
  // ManageCardsPage,
  ManageUsersPage,
  OnboardingPage,
  ResetPasswordPage,
  SettingsPage,
  TutorialsPage,
  UpgradePage,
} from "../pages";
import { UPGRADE_PAGES } from "../constants/pages";
import { Center } from "@chakra-ui/react";

export const routes = [
  {
    path: "/manage-users",
    component: ManageUsersPage,
    layout: NewLayout,
  },
  {
    path: "/contacts",
    component: ContactsPage,
    layout: NewLayout,
  },
  {
    path: "/contacts/:contactId",
    component: ContactDetailPage,
    layout: NewLayout,
  },
  {
    path: "/analytics",
    component: AnalyticsPage,
    layout: NewLayout,
  },
  {
    path: "/directory",
    component: DirectoryPage,
    layout: NewLayout,
  },
  {
    path: "/settings",
    component: SettingsPage,
    layout: NewLayout,
  },
  {
    path: "/business",
    component: BusinessPage,
    layout: NewLayout,
  },
  {
    path: "/integrations",
    component: IntegrationsPage,
    layout: NewLayout,
  },
  {
    path: "/manage-devices",
    component: DevicesPage,
    layout: NewLayout,
  },
  {
    path: "/tutorials",
    component: TutorialsPage,
    layout: NewLayout,
  },
  {
    path: "/auth/login",
    component: LoginPage,
    layout: ({ children }) => <Center height="full">{children}</Center>,
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPasswordPage,
    layout: AuthLayout,
  },
  {
    path: "/auth/reset-password",
    component: ResetPasswordPage,
    layout: AuthLayout,
  },
  {
    path: "/auth/logout",
    component: LogoutPage,
    layout: ({ children }) => <Center height="full">{children}</Center>,
  },
  {
    path: "/auth/login-with-token",
    component: LoginWithTokenPage,
    layout: ({ children }) => <Center height="full">{children}</Center>,
  },
  {
    path: "/onboarding",
    component: OnboardingPage,
    layout: OnboardingLayout,
  },
  {
    path: UPGRADE_PAGES.SELECT_YOUR_PLAN,
    component: UpgradePage,
    layout: ({ children }) => <Center height="full">{children}</Center>,
  },
];
