import { MAIN_SITE } from "../constants";
import { getToken } from "./token";

export interface LinkProps {
  profileID?: string;
  teamID?: string;
  roleId?: number;
  redirectTo?: string;
}

export const getLoginToOVOULink = ({
  profileID,
  teamID,
  roleId,
  redirectTo,
}: LinkProps) => {
  let { accessToken, refreshToken } = getToken();

  const params = new URLSearchParams({
    token: String(accessToken),
    refreshToken: String(refreshToken),
    ...(profileID && { profileID }),
    ...(teamID && { teamID }),
    ...(redirectTo && { redirectTo }),
    ...(roleId && { roleId: String(roleId) }),
  });

  return `${MAIN_SITE}/o/auth/login-with-token?${params.toString()}`;
};

export const openLoginAsUserPage = ({
  profileID,
  teamID,
  redirectTo,
}: LinkProps) => {
  const a = document.createElement("a");
  a.href = getLoginToOVOULink({
    profileID,
    teamID,
    redirectTo,
  });
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
