import { FC } from "react";
import WalkthroughModal from "../../components/WalkThrough";
import { usePermissionsState } from "context/Permissions";
import { useFlag } from "../../utils";
import { FEATURE_FLAGS } from "constants/flags";
import { useUserState } from "context";
import { useBoolean } from "@chakra-ui/react";
import { UPGRADE_PAGES } from "constants/pages";
const UpgradeModal: FC<{ showUpgradeModal: boolean }> = ({
  showUpgradeModal,
}) => {
  const isNewUpgrade = useFlag(FEATURE_FLAGS.NEW_UPGRADE);

  const { currentTeam } = useUserState();
  const { permission } = usePermissionsState();

  const [isWalkthroughModalOpen, { off: closeWalkthroughModal }] = useBoolean(
    Boolean(permission?.user_iam_role && showUpgradeModal),
  );

  const walkthroughId =
    permission?.user_iam_role && showUpgradeModal
      ? permission.user_iam_role
      : "";

  return (
    <WalkthroughModal
      isWalkthroughOpen={isWalkthroughModalOpen}
      closeWalkthrough={closeWalkthroughModal}
      walkthroughId={walkthroughId}
      defaultLink={
        isNewUpgrade
          ? ""
          : `/${UPGRADE_PAGES.SELECT_YOUR_PLAN}?teamId=${currentTeam?.id}&currency=USD&interval=year`
      }
    />
  );
};

export default UpgradeModal;
