import { FC } from "react";
import { Flex, Stack, StackDivider } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import SidebarHeader from "./Header";
import MenuItemComponent from "./MenuItem";
import { useGetMenuItems } from "./hook/useGetMenuItems";
import { ISidebar } from "../../types/ISidebar";

export const Sidebar: FC<
  Omit<ISidebar, "mainItems" | "secondaryItems">
> = props => {
  const { mainItems, secondaryItems } = useGetMenuItems();

  return (
    <SidebarView
      {...props}
      mainItems={mainItems}
      secondaryItems={secondaryItems}
    />
  );
};

export const SidebarView: FC<ISidebar> = ({
  mainItems,
  secondaryItems,
  isMobile,
  isOpen,
  close,
}) => {
  return (
    <div
      className={`flex flex-shrink-0 border-r border-gray-300 transition-all duration-150 ease-in-out ${
        isMobile ? "fixed inset-0 z-40" : ""
      }`}
      style={{ transform: isOpen ? "translateX(0)" : "translateX(-100%)" }}
    >
      <Flex
        flexDirection="column"
        backgroundColor={theme.palette.brand.primary.white}
        width={isMobile ? "full" : 72}
      >
        <SidebarHeader close={close} isMobile={isMobile} />

        <Flex
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          overflowY="auto"
          marginTop={8}
          marginBottom={4}
          paddingX={4}
        >
          <Stack spacing={0}>
            {mainItems.map(item => (
              <MenuItemComponent key={item.title} {...item} close={close} />
            ))}
          </Stack>

          <Stack
            marginTop={4}
            divider={
              <StackDivider borderColor={theme.palette.ui.greys.grey4} />
            }
          >
            {secondaryItems.map(item => (
              <MenuItemComponent
                key={item.title}
                {...item}
                textColor={theme.palette.brand.primary.black}
                sx={{
                  svg: {
                    color: theme.palette.ui.greys.grey1,
                  },
                }}
              />
            ))}
          </Stack>
        </Flex>
      </Flex>
    </div>
  );
};
