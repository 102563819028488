export enum targetType {
  ONBOARDING = "onboarding",
  TIPS = "tips",
}
interface IModuleCoverInfo {
  headline?: string;
  gradient: string;
  enable: boolean;
  poster: string;
}

type ModuleButtonProps =
  | { primary: string; secondary?: string }
  | { primary?: string; secondary: string };

// Define a union type to ensure one of the cover properties is present
interface IModuleCoverImage {
  coverImage: string; // Required if cover_video is not present
  coverVideo?: never; // Cannot be provided
}

interface IModuleCoverVideo {
  coverVideo: string; // Required if cover_image is not present
  coverImage?: never; // Cannot be provided
}

export type TModuleCover = (IModuleCoverImage | IModuleCoverVideo) &
  ModuleButtonProps &
  IModuleCoverInfo;

export const MODULE_COVER: TModuleCover = {
  coverVideo: "/discovery_module/cover.mp4",
  headline: "Beyond a Card: Learn How OVOU Boosts Your Networking",
  gradient: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
  primary: "Learn How It Works",
  enable: true,
  poster: "/discovery_module/profile.png",
};

interface IModule {
  moduleName: string;
  isActive: boolean;
  showCloseBtn: boolean;
  order: number;
  target: TargetType; // e.g., 'onboarding' or 'tips'
  gradient: string;
}

export const DISCOVERY_MODULE: IModule = {
  moduleName: "Discovery Module",
  isActive: true,
  showCloseBtn: false,
  order: 1,
  target: targetType.ONBOARDING,
  gradient:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)",
};

type TargetType = (typeof targetType)[keyof typeof targetType];

export interface IModuleContent {
  headline?: string;
  desc?: string;
  src: string;
  sound_enabled?: boolean;
  duration: number;
  order: number;
  enable: boolean;
  target: TargetType[];
  href?: string;
  as?: string;
  blobUrl?: string;
  poster: string;
}

export const DISCOVERY_MODULE_CONTENT: IModuleContent[] = [
  {
    src: "/discovery_module/part_1.mp4",
    duration: 14000,
    order: 1,
    enable: true,
    sound_enabled: true,
    target: [targetType.ONBOARDING],
    poster: "/discovery_module/poster_1.png",
    headline: "Profile: More Than Just a Page",
    desc: "Your OVOU profile is your story—a powerful tool to showcase yourself, your work, and your values, creating a lasting impression.",
  },

  {
    src: "/discovery_module/part_2.mp4",
    duration: 15000,
    order: 2,
    enable: true,
    sound_enabled: true,
    target: [targetType.ONBOARDING],
    poster: "/discovery_module/poster_2.png",
    headline: "Share Your Profile with a Tap",
    desc: "Effortlessly share your profile with a simple tap of your OVOU card, connecting others to your story.",
  },

  {
    src: "/discovery_module/part_3.mp4",
    duration: 21000,
    order: 3,
    enable: true,
    sound_enabled: true,
    target: [targetType.ONBOARDING],
    poster: "/discovery_module/poster_2.png",
    headline: "Multiple Sharing Options",
    desc: "Share your profile beyond the card with virtual cards, QR codes, email signatures, virtual backgrounds, links, or directly in your social bio.",
  },

  {
    src: "/discovery_module/part_4.mp4",
    duration: 16000,
    order: 4,
    enable: true,
    sound_enabled: true,
    target: [targetType.ONBOARDING],
    poster: "/discovery_module/poster_2.png",
    headline: "Build Your Network",
    desc: "When people visit your profile, they can save your contact or exchange theirs, helping you grow a valuable network.",
  },

  {
    src: "/discovery_module/part_5.mp4",
    duration: 11000,
    order: 5,
    enable: true,
    sound_enabled: true,
    target: [targetType.ONBOARDING],
    poster: "/discovery_module/poster_2.png",
    headline: "Take Control",
    desc: "Analytics, contact management, and automation features help you gain insights and manage your networking process effectively.",
  },
];
